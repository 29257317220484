<template>
  <v-dialog :value="value" persistent width="800">
    <v-form @submit.prevent="submit">
      <v-card class="pa-5">
        <v-card-title class="px-0 pt-0">
          Neuer Brief
          <v-spacer />
          <v-btn icon @click="closeDialog">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-select
          dense
          outlined
          label="Vorlagenart"
          :items="types"
          v-model="type"
          @change="onSelectType"
        ></v-select>
        <v-select
          v-if="template"
          v-model="template"
          dense
          outlined
          label="Vorlage"
          :items="templates"
          item-text="bezeichnung"
          item-value="id"
          return-object
          @change="selectTemplate"
        ></v-select>
        <!-- Auswahl Blanko oder Vorlage -->
        <template v-if="type === null"></template>
        <!-- Auswahl aus Vorlagenliste -->
        <template v-else-if="type === 'template'">
          <template v-if="template === null">
            <v-row v-if="loading">
              <v-col v-for="n in 3" :key="n" cols="4">
                <v-skeleton-loader type="card" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                v-for="(vorlage, index) in templates"
                :key="`template-${index}`"
                cols="4"
              >
                <v-card class="pa-5" @click="selectTemplate(vorlage)">
                  <v-card-title class="pt-0 px-0 text-h6">
                    {{ vorlage.bezeichnung }}
                  </v-card-title>
                  <v-card-text class="px-0">
                    {{ cutString(vorlage.nachricht, 100) }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <template v-if="userType === 'kunde'">
              <v-select
                dense
                outlined
                label="Anschreiben"
                :items="adressAuswahl"
                v-model="selectedAdresstyp"
              ></v-select>
              <v-sheet outlined rounded class="pa-5 mb-5">
                <p class="text-h6">Versandadresse:</p>
                <p v-if="selectedAdresstyp === 'Heimadresse'">
                  {{ user.anrede }} {{ user.vorname }} {{ user.nachname }}<br />
                  <span v-if="user.adresszusatz"
                    >{{ user.adresszusatz }}<br
                  /></span>
                  {{ user.strasse }} {{ user.hausnr }}<br />
                  {{ user.plz }} {{ user.ort }}
                </p>
                <p v-else-if="user.bauvorhaben.length === 1">
                  {{ user.anrede }} {{ user.vorname }} {{ user.nachname }}<br />
                  <template v-if="hasBauvorhabenAdresse">
                    {{ user.bauvorhaben[0].strasse }}
                    {{ user.bauvorhaben[0].hausnr }}<br />
                    {{ user.bauvorhaben[0].plz }} {{ user.bauvorhaben[0].ort }}
                  </template>
                  <template v-else>
                    <v-icon color="error" class="mr-2">{{
                      icons.mdiClose
                    }}</v-icon
                    >Keine Adresse für das Bauvorhaben hinterlegt!
                    <!--              <v-btn depressed @click="pushToCompletion" class="ml-2"-->
                    <!--                >Adresse hinterlegen</v-btn-->
                    <!--              >-->
                  </template>
                </p>
                <template v-else>
                  <v-select
                    dense
                    outlined
                    label="Bauvorhaben auswählen"
                    :items="user.bauvorhaben"
                    item-text="bv_nr"
                    item-value="id"
                    v-model="selectedBauvorhaben"
                    class="mt-5"
                  ></v-select>
                  <p>
                    {{ user.anrede }} {{ user.vorname }} {{ user.nachname
                    }}<br />
                    {{ selectedBauvorhaben.strasse }}
                    {{ selectedBauvorhaben.hausnr }}<br />
                    {{ selectedBauvorhaben.plz }} {{ selectedBauvorhaben.ort }}
                  </p>
                </template>
              </v-sheet>
            </template>
            <v-sheet
              v-else-if="userType === 'dienstleister'"
              outlined
              rounded
              class="pa-5 mb-5"
            >
              <p class="text-h6">Versandadresse:</p>
              <p>
                {{ user.name }}<br />
                <span v-if="user.adresszusatz"
                  >{{ user.adresszusatz }}<br
                /></span>
                {{ user.strasse }} {{ user.hausnr }}<br />
                {{ user.plz }} {{ user.ort }}
              </p>
            </v-sheet>
            <v-text-field
              dense
              outlined
              label="Betreff"
              v-model="subject"
            ></v-text-field>
            <v-textarea
              dense
              auto-grow
              outlined
              label="Nachricht"
              v-model="message"
              rows="10"
            ></v-textarea>
            <p class="text--disabled">
              Mit freundlichen Grüßen<br />
              {{ authUser.vorname }} {{ authUser.nachname }}
            </p>
            <!--            <p class="text&#45;&#45;disabled">-->
            <!--              Talis<sup>&reg;</sup> Holzhäuser<br />-->
            <!--              Gehrmann u. Hinrichs GmbH & Co. KG<br />-->
            <!--              Johannes-Mejer-Str. 20<br />-->
            <!--              25813 Husum<br />-->
            <!--              Handelsregister Husum HRA 1841<br />-->
            <!--              Geschäftsführer: Bernd Hinrichs, Dipl.-Ing. Peer Gehrmann, Rafael-->
            <!--              Gettler<br /><br />-->
            <!--              www.talishaus.de<br />-->
            <!--              www.facebook.com/talishaus<br />-->
            <!--              www.instagram.com/talis.haus-->
            <!--            </p>-->
          </template>
        </template>
        <!-- Blanko -->
        <template v-else>
          <template v-if="userType === 'kunde'">
            <v-select
              dense
              outlined
              label="Anschreiben"
              :items="adressAuswahl"
              v-model="selectedAdresstyp"
            ></v-select>
            <v-sheet outlined rounded class="pa-5 mb-5">
              <p class="text-h6">Versandadresse:</p>
              <p v-if="selectedAdresstyp === 'Heimadresse'">
                {{ user.anrede }} {{ user.vorname }} {{ user.nachname }}<br />
                <span v-if="user.adresszusatz"
                  >{{ user.adresszusatz }}<br
                /></span>
                {{ user.strasse }} {{ user.hausnr }}<br />
                {{ user.plz }} {{ user.ort }}
              </p>
              <p
                v-else-if="
                  userType === 'kunde' && user.bauvorhaben.length === 1
                "
              >
                {{ user.anrede }} {{ user.vorname }} {{ user.nachname }}<br />
                <template v-if="hasBauvorhabenAdresse">
                  {{ user.bauvorhaben[0].strasse }}
                  {{ user.bauvorhaben[0].hausnr }}<br />
                  {{ user.bauvorhaben[0].plz }} {{ user.bauvorhaben[0].ort }}
                </template>
                <template v-else>
                  <v-icon color="error" class="mr-2">{{
                    icons.mdiClose
                  }}</v-icon
                  >Keine Adresse für das Bauvorhaben hinterlegt!
                  <!--              <v-btn depressed @click="pushToCompletion" class="ml-2"-->
                  <!--                >Adresse hinterlegen</v-btn-->
                  <!--              >-->
                </template>
              </p>
              <template v-else>
                <v-select
                  dense
                  outlined
                  label="Bauvorhaben auswählen"
                  :items="user.bauvorhaben"
                  item-text="bv_nr"
                  item-value="id"
                  v-model="selectedBauvorhaben"
                  class="mt-5"
                ></v-select>
                <p>
                  {{ user.anrede }} {{ user.vorname }} {{ user.nachname }}<br />
                  {{ selectedBauvorhaben.strasse }}
                  {{ selectedBauvorhaben.hausnr }}<br />
                  {{ selectedBauvorhaben.plz }} {{ selectedBauvorhaben.ort }}
                </p>
              </template>
            </v-sheet>
          </template>
          <v-sheet
            v-else-if="userType === 'dienstleister'"
            outlined
            rounded
            class="pa-5 mb-5"
          >
            <p class="text-h6">Versandadresse:</p>
            <p>
              {{ user.name }}<br />
              <span v-if="user.adresszusatz"
                >{{ user.adresszusatz }}<br
              /></span>
              {{ user.strasse }} {{ user.hausnr }}<br />
              {{ user.plz }} {{ user.ort }}
            </p>
          </v-sheet>
          <v-text-field
            outlined
            dense
            label="Betreff"
            v-model="subject"
          ></v-text-field>
          <v-textarea
            auto-grow
            dense
            outlined
            label="Nachricht"
            v-model="message"
            rows="10"
          ></v-textarea>
          <p class="text--disabled">
            Mit freundlichen Grüßen<br />
            {{ authUser.vorname }} {{ authUser.nachname }}
          </p>
          <!--          <p class="caption text&#45;&#45;disabled">-->
          <!--            Talis<sup>&reg;</sup> Holzhäuser<br />-->
          <!--            Gehrmann u. Hinrichs GmbH & Co. KG<br />-->
          <!--            Johannes-Mejer-Str. 20<br />-->
          <!--            25813 Husum<br />-->
          <!--            Handelsregister Husum HRA 1841<br />-->
          <!--            Geschäftsführer: Bernd Hinrichs, Dipl.-Ing. Peer Gehrmann, Rafael-->
          <!--            Gettler<br /><br />-->
          <!--            www.talishaus.de<br />-->
          <!--            www.facebook.com/talishaus<br />-->
          <!--            www.instagram.com/talis.haus-->
          <!--          </p>-->
        </template>
        <v-card-actions class="px-0 pb-0">
          <v-spacer />
          <v-btn depressed @click="closeDialog">Abbrechen</v-btn>
          <v-btn
            depressed
            :disabled="createButtonDisabled"
            type="submit"
            color="cta"
            class="white--text"
            >Erstellen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "NewAnschreibenDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: "interessent",
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      subject: null,
      message: null,
      adressAuswahl: ["Heimadresse", "Baustellenadresse"],
      selectedAdresstyp: "Heimadresse",
      selectedBauvorhaben: {},
      types: [
        { text: "Blanko", value: "blank" },
        { text: "Vorlage", value: "template" },
      ],
      type: null,
      templates: [],
      template: null,
      loading: false,
      generatingAnschreiben: false,
    };
  },
  watch: {
    type(val) {
      if (val === "template") {
        this.fetchBrieftemplates();
      }
    },
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    anrede() {
      let anrede = "";

      if (this.$props.user.anrede && this.$props.user.anrede.includes("Herr")) {
        anrede = "Sehr geehrter ";
      } else if (this.$props.userType === "dienstleister") {
        anrede = "Sehr geehrte Damen und Herren,";
      } else {
        anrede = "Sehr geehrte ";
      }

      return anrede;
    },
    hasBauvorhabenAdresse() {
      return (
        this.$props.user.bauvorhaben[0].strasse &&
        this.$props.user.bauvorhaben[0].hausnr &&
        this.$props.user.bauvorhaben[0].plz &&
        this.$props.user.bauvorhaben[0].ort
      );
    },
    createButtonDisabled() {
      return (
        !this.type ||
        (this.selectedAdresstyp === "Baustellenadresse" &&
          !this.hasBauvorhabenAdresse) ||
        (this.type === "template" && this.templates.length === 0)
      );
    },
  },
  methods: {
    async fetchBrieftemplates() {
      const query = {
        templateType: "brief",
        view: "personal",
      };

      this.loading = true;
      await ApiService.fetchTemplates(query)
        .then((res) => {
          this.templates = res.data.collection;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            message: "Beim Laden der Vorlagen ist ein Fehler aufgetreten:",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelectType() {
      if (this.type === "template") {
        this.fetchBrieftemplates();
      } else {
        this.resetTemplate();
      }
    },
    resetTemplate() {
      this.template = null;
      this.subject = null;
      this.message = null;
    },
    selectTemplate(template) {
      this.template = template;
      this.subject = this.template.betreff;
      this.message = this.template.nachricht;
    },
    cutString(text, maxlength) {
      if (text.length <= maxlength) {
        return text;
      }
      return text.substr(0, maxlength) + "\u2026";
    },
    async submit() {
      const payload = {
        userType: this.$props.userType,
        userId: this.$props.user.id,
        templateType: this.type,
        templateId: this.template ? this.template.id : null,
        subject: this.subject,
        message: this.message,
        adressTyp: this.selectedAdresstyp,
      };

      this.generatingAnschreiben = true;
      await ApiService.generateCustomAnschreiben(payload)
        .then((res) => {
          this.$emit("submit");
          this.resetTemplate();
          this.type = null;
          const link = document.createElement("a");
          link.href = "data:application/pdf;base64," + res.data.filecontents;
          link.download = res.data.filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler beim Erstellen der Datei",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.generatingAnschreiben = false;
        });
    },
    closeDialog() {
      this.template = null;
      this.type = null;
      this.$emit("dialog:close");
    },
  },
};
</script>

<style scoped></style>
